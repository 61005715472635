import { FormControl, SvgIcon, TextField, makeStyles, useTheme, } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FetchAutocompleteSuggestions, FetchSearchResults } from "./Service/SearchService";
import { usePageContext } from "./Context/PageContextProvider";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useSearchResultsContext } from "./Context/SearchResultsContext";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "60px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        marginRight: "24px",
        "& .MuiOutlinedInput-input": {
            padding: "0px 0px",
            border: "none",
        },
        "& .MuiInputBase-input": {
            "&::placeholder": {
                color: "#323537",
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: "65px",
        },
    },
    wideFormControl: {
        width: "400px",
        marginTop: "12px",
        marginBottom: "12px",
        border: "1px solid #7F7F7F",
        paddingLeft: "8px",
        marginRight: "0px",
        [theme.breakpoints.down("md")]: {
            marginRight: "24px",
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
            paddingLeft: "10px",
            width: "100%",
        },
    },
    searchIcon: (props) => ({
        width: "24px",
        height: "24px",
        paddingRight: props.isOpen ? "5px" : "0px",
        "&:hover": {
            cursor: "pointer",
        },
    }),
    closeIcon: {
        width: "24px",
        height: "24px",
        paddingRight: "8px",
    },
    autocomplete: {
        textAlign: "start",
        width: "100%",
        paddingLeft: "8px",
        paddingBottom: "5px",
        "& input::placeholder": {
            color: theme.colorCard?.bodyText,
            opacity: 1,
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none",
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "& .MuiAutocomplete-input:first-child": {
            marginTop: "5px",
        },
    },
}));
const SearchBox = (props) => {
    const theme = useTheme();
    const pageContext = usePageContext();
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles({ isOpen });
    const history = useHistory();
    const searchContext = useSearchResultsContext();
    const [SearchResultsContext, setSearchResultsContext] = useState(searchContext);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const { startPageData } = usePageContext();
    useEffect(() => {
        setSearchResultsContext(SearchResultsContext);
    }, [SearchResultsContext.query]);
    useEffect(() => {
        isOpen ? props.onSearchBoxOpen(isOpen) : props.onSearchBoxClose(isOpen);
    }, [isOpen]);
    const performSearch = async (searchQuery) => {
        const siteId = pageContext.startPageData.contentLink.id;
        const searchResults = await FetchSearchResults(searchQuery, `${siteId}`, 5, theme.brand);
        return searchResults;
    };
    function goToSearchResultsPage() {
        let pathToSearchResultsPage = "/sokresultat";
        if (startPageData &&
            startPageData.searchResultsPageReference &&
            startPageData.searchResultsPageReference.value) {
            const absolutePath = startPageData.searchResultsPageReference.value.toString();
            pathToSearchResultsPage = getSearchResultsPath(absolutePath);
        }
        function getSearchResultsPath(absolutePath) {
            const actualPathToSearchResultsPage = new URL(absolutePath).pathname;
            let webshopPath = pathToSearchResultsPage !== actualPathToSearchResultsPage
                ? actualPathToSearchResultsPage
                : pathToSearchResultsPage;
            // Ensure the search query is URL-safe
            const encodedSearchQuery = encodeURIComponent(inputValue);
            webshopPath += `?q=${encodedSearchQuery}`;
            return webshopPath;
        }
        history.push(pathToSearchResultsPage);
    }
    const handleCloseIconClick = () => {
        // Clear the search query
        setInputValue("");
        setIsOpen(false);
    };
    const handleEnterKeyDown = async (event) => {
        const inputValue = event.target.value;
        setInputValue(inputValue);
        if (inputValue) {
            searchContext.updateSearchQuery(inputValue);
            const results = await performSearch(inputValue);
            if (results) {
                searchContext.updateSearchResults(results);
            }
            goToSearchResultsPage();
        }
    };
    const handleOnFocus = () => {
        setIsOpen(true);
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        fetchAutocompleteOptions(newInputValue);
    };
    const fetchAutocompleteOptions = async (inputValue) => {
        if (!inputValue || inputValue.length < 3) {
            setSuggestions([]);
            return;
        }
        const response = await FetchAutocompleteSuggestions(inputValue);
        const data = await response.json();
        setSuggestions(data.suggestions);
    };
    return (React.createElement(FormControl, { variant: "outlined", className: clsx(classes.formControl, isOpen && classes.wideFormControl) },
        React.createElement(SvgIcon, { onClick: () => setIsOpen(true), className: classes.searchIcon }, theme.icons?.searchIcon),
        React.createElement(Autocomplete, { className: classes.autocomplete, disableClearable: true, placeholder: props.placeholder, freeSolo: true, value: suggestions.find((suggestion) => suggestion === inputValue) || "", id: "searchInputField", onChange: (event, newValue) => {
                handleInputChange(event, newValue);
            }, inputValue: inputValue, onInputChange: (event, newValue) => handleInputChange(event, newValue), options: suggestions, onOpen: () => fetchAutocompleteOptions(inputValue), onFocus: handleOnFocus, onKeyDown: (event) => {
                if (event.key === "Enter") {
                    handleEnterKeyDown(event);
                }
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: props.placeholder }))) }),
        isOpen && (React.createElement(SvgIcon, { onClick: handleCloseIconClick, className: classes.closeIcon }, theme.icons?.closeIcon))));
};
export default SearchBox;
